@font-face {
  font-family: 'lae-icomoon';
  src:
    url('fonts/lae-icomoon.ttf?w77gxm') format('truetype'),
    url('fonts/lae-icomoon.woff?w77gxm') format('woff'),
    url('fonts/lae-icomoon.svg?w77gxm#lae-icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="lae-icon-"], [class*=" lae-icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'lae-icomoon' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.lae-icon-play:before {
  content: "\e925";
}
.lae-icon-link:before {
  content: "\e924";
}
.lae-icon-star-empty:before {
  content: "\e901";
}
.lae-icon-rate:before {
  content: "\e901";
}
.lae-icon-star:before {
  content: "\e901";
}
.lae-icon-favorite:before {
  content: "\e901";
}
.lae-icon-bookmark:before {
  content: "\e901";
}
.lae-icon-eye:before {
  content: "\e902";
}
.lae-icon-like:before {
  content: "\e900";
}
.lae-icon-calendar:before {
  content: "\e923";
}
.lae-icon-bubble:before {
  content: "\e903";
}
.lae-icon-comment:before {
  content: "\e903";
}
.lae-icon-chat:before {
  content: "\e903";
}
.lae-icon-talk:before {
  content: "\e903";
}
.lae-icon-arrow-left:before {
  content: "\e904";
}
.lae-icon-arrow-right:before {
  content: "\e905";
}
.lae-icon-icon-aim:before {
  content: "\e906";
}
.lae-icon-icon-behance:before {
  content: "\e907";
}
.lae-icon-icon-dribbble:before {
  content: "\e908";
}
.lae-icon-icon-facebook:before {
  content: "\e909";
}
.lae-icon-icon-flickr:before {
  content: "\e90a";
}
.lae-icon-icon-googleplus:before {
  content: "\e90b";
}
.lae-icon-icon-linkedin:before {
  content: "\e90c";
}
.lae-icon-icon-pinterest:before {
  content: "\e90d";
}
.lae-icon-icon-skype:before {
  content: "\e90e";
}
.lae-icon-icon-twitter:before {
  content: "\e90f";
}
.lae-icon-icon-vimeo:before {
  content: "\e910";
}
.lae-icon-icon-zerply:before {
  content: "\e911";
}
.lae-icon-quote:before {
  content: "\e912";
}
.lae-icon-video-play:before {
  content: "\e913";
}
.lae-icon-icon-email:before {
  content: "\e914";
}
.lae-icon-close:before {
  content: "\e915";
}
.lae-icon-icon-plus:before {
  content: "\e916";
}
.lae-icon-arrow-right-toggle:before {
  content: "\e917";
}
.lae-icon-menu:before {
  content: "\e918";
}
.lae-icon-menu-2:before {
  content: "\e919";
}
.lae-icon-fit-to:before {
  content: "\e91a";
}
.lae-icon-full-screen:before {
  content: "\e91b";
}
.lae-icon-arrow-left2:before {
  content: "\e91c";
}
.lae-icon-arrow-left3:before {
  content: "\e91d";
}
.lae-icon-arrow-right2:before {
  content: "\e91e";
}
.lae-icon-arrow-right3:before {
  content: "\e91f";
}
.lae-icon-start:before {
  content: "\e920";
}
.lae-icon-heart:before {
  content: "\e921";
}
.lae-icon-retweet:before {
  content: "\e922";
}
.lae-icon-play2:before {
  content: "\ea15";
}
.lae-icon-play3:before {
  content: "\ea1c";
}
.lae-icon-instagram:before {
  content: "\ea92";
}
.lae-icon-twitter:before {
  content: "\ea96";
}
